import HTTP from "@/utils/http";
import requestHandler from "@/utils/requestHandler";
import router from "@/router";
import { startLoading, stopLoading } from "@/utils/loading";
import AuthorizationContext from "@/sso/keycloak-instance.js";

export default {
  namespaced: true,

  state: {
    User: {},
    Tenant: {},
    Token: "",
    needsNewAgreementConfirmation: false,
    Agreement: {},
    SSOInfo: {
      session_id: null,
      device_id: null,
    },
  },

  getters: {
    User: (state) => state.User,
    hasTenant: (state) => state.Token.type === "tenant",
    Tenant: (state) => state.Tenant,
    Token: (state) => state.Token,
    needsNewAgreementConfirmation: (state) =>
      state.needsNewAgreementConfirmation,
    languageId: (state) => state.User.languageId,
    baseCurrencyId: (state) => state.Tenant.baseCurrencyId,
    Profile: (state) => ({
      user: state.User,
      tenant: state.Tenant,
    }),
    agreement: (state) => state.Agreement,
    SSOInfo: (state) => state.SSOInfo,
  },

  actions: {
    async createUser({ commit, dispatch }, user) {
      const results = await requestHandler({
        title: "createUser",
        methodName: "createUser",
        method: () => {
          return HTTP.post("User", user);
        },
        success: (result) => {
          commit("USER_CREATE", result.data.user);
          commit("CHANGE_TOKEN", {
            value: result.data.token.value,
            type: "user",
          });
          return result;
        },
      });
      return results;
    },

    async createUserWithTenant(_, user) {
      const results = await requestHandler({
        title: "Auth/createUserWithTenat",
        methodName: "Auth/createUserWithTenat",
        method: () => {
          return HTTP.post("User/new", user);
        },
        async success() {
          await AuthorizationContext.getNewToken()
          HTTP.defaults.headers.common["Authorization"] = AuthorizationContext.keycloakInstance.token
          router.push({ name: "Dashboard" });
        },
      });
      return results;
    },

    async createTenant({ commit, dispatch }, tenant) {
      const results = await requestHandler({
        title: "Create Tenant",
        methodName: "Auth/createTenant",
        method: () => {
          return HTTP.post("Tenant", tenant);
        },
        success: (result) => {
          commit("TENANT_CREATE", result.data.tenant);
          commit("CHANGE_TOKEN", {
            value: result.data.token.value,
            type: "tenant",
          });
          return result;
        },
      });

      return results;
    },

    async getAgreement({ commit }, LanguageId) {
      const results = await requestHandler({
        title: "getAgreement",
        methodName: "getAgreement",
        method: () => {
          return HTTP.get("Agreement", { params: { LanguageId } });
        },
        success: (result) => {
          commit("SET_AGREEMENT", result.data.agreement);
          return result;
        },
      });
      return results;
    },

    async getUserAgreement({ commit }) {
      const results = await requestHandler({
        title: "getUserAgreement",
        methodName: "getUserAgreement",
        method: () => {
          return HTTP.get("Agreement/user");
        },
        success: (result) => {
          commit("SET_AGREEMENT", result.data.agreement);
          return result;
        },
      });
      return results;
    },

    async confirmAgreement({ commit }, agreementId) {
      const results = await requestHandler({
        title: "confirmAgreement",
        methodName: "confirmAgreement",
        method: () => {
          return HTTP.post("Agreement/user/confirm", { agreementId });
        },
        success: (result) => result,
      });
      return results;
    },

    async Login({ commit, dispatch }, user) {
      const results = await requestHandler({
        title: "Auth Login",
        methodName: "Auth/Login",
        method: () => {
          return HTTP.post("Token", user);
        },
        success: async (result) => {
          commit("USER_CREATE", result.data.user);
          commit("TENANT_CREATE", result.data.tenant);
          commit("ControlAgreement", result.data.needsNewAgreementConfirmation);
          commit("CHANGE_TOKEN", {
            value: result.data.token.value,
            type: result.data.tenant ? "tenant" : "user",
            refreshToken: result.data.token.refreshToken,
          });

          await dispatch("Settings/fetchUserForPermission", null, {
            root: true,
          });
          return result;
        },
        incorrect: (errorResult) => {
          return errorResult;
        },
      });
      return results;
    },

    async RefreshToken({ commit, dispatch }) {
      let result;
      const ls = JSON.parse(
        localStorage.getItem(
          window.VUE_APP_VUEX_NAME || process.env.VUE_APP_VUEX_NAME
        )
      );
      const ExpiredToken = ls.Auth.Token.value;
      const RefreshToken = ls.Auth.Token.refreshToken;
      const params = {
        ExpiredToken,
        RefreshToken,
      };
      const loadingName = "Token/refresh";
      try {
        result = await startLoading(dispatch, loadingName, () => {
          return HTTP.post("Token/refresh", params);
        });
        commit("CHANGE_TOKEN", {
          value: result.data.token.value,
          type: result.data.tenant ? "tenant" : "user",
          refreshToken: result.data.token.refreshToken,
        });
      } catch (err) {
        if (err.response) {
          stopLoading(dispatch, loadingName);
          dispatch("Logout");
          window.history.replaceState({}, '', '/')
          router.push({
            name: "Login",
          });
          return err.response;
        }
      }
      return result;
    },

    async updateUser({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: "Auth Update User",
        methodName: "Auth/updateUser",
        method: () => {
          return HTTP.put("User", payload);
        },
        success: (result) => {
          commit("UPDATE_USER", result.data.user);
          return result;
        },
      });
      return results;
    },

    async confirmEmail({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: "confirmEmail",
        methodName: "confirmEmail",
        method: () => {
          return HTTP.put("User/confirmemail", payload);
        },
        success: (result) => result,
      });
      return results;
    },

    async getTenant({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: "Auth Get Tenant",
        methodName: "Auth/getTenant",
        method: () => {
          return HTTP.get("Tenant", { params: payload });
        },
        success: (result) => {
          commit("UPDATE_TENANT", result.data.tenant);
          return result;
        },
      });
      return results;
    },

    async updateTenant({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: "Auth Update Tenant",
        methodName: "Auth/updateTenant",
        method: () => {
          return HTTP.put("Tenant", payload);
        },
        success: (result) => {
          commit("UPDATE_TENANT", result.data.tenant);
          return result;
        },
      });
      return results;
    },

    async updatePassword({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: "Auth updatePassword",
        methodName: "Auth/changePassword",
        method: () => {
          return HTTP.put("User/changePassword", payload);
        },
        success: (result) => result,
      });
      return results;
    },

    async Logout({ commit }) {
      commit("Auth/RESET", null, { root: true });
      commit("ListsInventory/RESET_STORE_FILTER", null, { root: true });
      commit("Settings/RESET", null, { root: true });
      commit("Dashboard/RESET", null, { root: true });
      commit("Tax/RESET", null, { root: true });
      commit("Group/RESET", null, { root: true });
      commit("IncomeStats/RESET", null, { root: true });
      commit("Supplier/RESET", null, { root: true });
      commit("Stock/RESET", null, { root: true });
      commit("RecipeGroups/RESET", null, { root: true });
      commit("Recipes/RESET", null, { root: true });
      commit("Despatch/RESET", null, { root: true });
      commit("Inventories/RESET", null, { root: true });
      commit("OnHand/RESET", null, { root: true });
      commit("IntegrationB2bLogo/RESET_STORE_GROUP", null, { root: true });
      commit("Global/SET_SUPPORT_USER_STATUS", false, { root: true })
    },

    async ForgotPassword({ commit }, payload) {
      const results = await requestHandler({
        title: "Forgot Password",
        methodName: "ForgotPassword",
        method: () => {
          return HTTP.post("User/forgotpassword", payload);
        },
        success: (result) => result,
      });

      return results;
    },

    async ResetPassword({ commit }, payload) {
      const results = await requestHandler({
        title: "Reset Password",
        methodName: "ResetPassword",
        method: () => {
          return HTTP.post("User/resetpassword", payload);
        },
        success: (result) => result,
      });

      return results;
    },
  },

  mutations: {
    RESET(state) {
      state.Customer && delete state.Customer;
      HTTP.defaults.headers.common["Authorization"] = "";
      Object.assign(state, {
        User: {},
        Token: "",
        Tenant: {
          baseCurrencyId: "",
        },
        SSOInfo: {
          session_id: null,
          device_id: null,
        },
      });
    },

    CHANGE_TOKEN(state, token) {
      state.Token = token;
      HTTP.defaults.headers.common["Authorization"] = "Bearer " + token.value;
    },

    USER_CREATE(state, user) {
      state.User = user;
    },

    ControlAgreement(state, needsNewAgreementConfirmation) {
      state.needsNewAgreementConfirmation = needsNewAgreementConfirmation;
    },

    TENANT_CREATE(state, tenant) {
      state.Tenant = tenant;
    },

    UPDATE_USER(state, user) {
      state.User.firstname = user.firstname;
      state.User.surname = user.surname;
      state.User.languageId = user.languageId;
      state.User.isOutOfOffice = user.isOutOfOffice;
      state.User.phoneNumber = user.phoneNumber;
    },

    UPDATE_TENANT(state, tenant) {
      state.Tenant = tenant
    },
    SET_AGREEMENT(state, agreement) {
      state.Agreement = agreement;
    },
    SET_SSO_INFO(state, SSOInfo) {
      state.SSOInfo = SSOInfo;
    },
  },
};
