import Login from '../login'
import Register from '../register'
import Tenant from '../tenant'
import AgreeConfirm from '../confirm-agree'
import ForgotPassword from '../forgot-password'
import ResetPassword from '../reset-password'
import ConfirmEmail from '../confirm-email'

export default [
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      auth: true
    }
  },
  {
    path: '/tenant',
    name: 'Tenant',
    component: Tenant,
    meta: {
      auth: true
    }
  },
  {
    path: '/agreementConfirm',
    name: 'AgreeConfirm',
    component: AgreeConfirm
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      auth: true
    }
  },
  {
    path: '/supportuser',
    name: 'SupportUser',
    component: Login,
    meta: {
      auth: true
    }
  },
  {
    path: '/ForgotPassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      auth: true
    }
  },
  {
    path: '/ResetPassword',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      auth: true
    }
  },
  {
    path: '/ConfirmEmail',
    name: 'ConfirmEmail',
    component: ConfirmEmail,
    meta: {
      auth: true
    }
  }
]
